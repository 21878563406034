import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbmaddon from "src/components/trbmaddon"

const addondata = import("./data/addondata.json");

export default function trbmaddon() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br {
      position: relative;
      color: #f1f1f1;
      font-weight: light;
    }

    .footerlinks-module--br a {
      color: #f1f1f1;
    }
    `}
    </style>
    <title>Free PDF Editor by PDFtab</title></Helmet>
    <section>
      <Trbmaddon data={addondata}></Trbmaddon>
      </section>
    </HomepageLayout>
  )
}
