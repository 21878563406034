import React from "react"
import TrbmaddonData from "./data/trbmaddon.json"
import CtaDisclosure from "@tightrope/ctadisclosurenewtab"
import FooterLinks from "@tightrope/footerlinks/footer.js"
import Styles from "./css/trbmaddon.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
class Trbmaddon extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: TrbmaddonData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
        if (typeof window != "undefined") {
            window.mapParams();
        }
    });
  }

  render() {
    return (
      <div className = {Styles.trbmaddon} style={{ display: this.state.display }}>
          <div>
        <div className={Styles.card}>
          <div className={Styles.wrap}>
            <img className={Styles.logo} src={this.state.data.logo} />
            <h1 className="hc_param">
              {this.state.data.headline}
            </h1>
            <h2>{this.state.data.subhead}</h2>
            <ul className={Styles.step}>
              {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
              </ul>
            <button
              className={Styles.cwsbtn}
              onClick = {() => window.triggerInstall()}
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
              <CtaDisclosure></CtaDisclosure>
          </div>
        </div>
        <FooterLinks></FooterLinks>

        <div className={Styles.addon}>
          <h3>
            {this.state.data.addontitle}
          </h3>

          <div className={Styles.gridContainer}>
            <div className={Styles.temp}>
              <img
                src={this.state.data.listimage}
                className={Styles.listimg} />
              <ul className={Styles.list}>
                {this.state.data.listLeft != undefined ? this.state.data.listLeft.map((bullet, i) => {
                    return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                  }) : null}
                </ul>
            </div>

            <div className={Styles.temp}>
            <img
              src={this.state.data.listimage}
              className={Styles.listimg} />
            <ul className={Styles.list}>
                {this.state.data.listCenter != undefined ? this.state.data.listCenter.map((bullet, i) => {
                    return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                  }) : null}
                </ul>
            </div>

            <div className={Styles.temp}>
            <img
              src={this.state.data.listimage}
              className={Styles.listimg} />
            <ul className={Styles.list}>
                {this.state.data.listRight != undefined ? this.state.data.listRight.map((bullet, i) => {
                    return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                  }) : null}
                </ul>
            </div>
          </div>
        </div>

          </div>
        </div>




    )
  }
}
export default Trbmaddon
